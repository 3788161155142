<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-between"
        >
          <b-row>
            <b-col
              md="2"
              offset-md="4"
              class="d-flex justify-content-around"
            >
              <b-alert
                variant="danger"
                show
                class="mr-4"
              >
                <h4 class="alert-heading text-center">
                  Operações: {{ totalOperacaoId }}
                </h4>
              </b-alert>
              <b-alert
                variant="danger"
                show
              >
                <h4 class="alert-heading text-center">
                  CPF: {{ totaisCpf }}
                </h4>
              </b-alert>
            </b-col>
          </b-row>
          <b-row
            class="mr-1"
          >
            <b-col>
              <b-row
                align-h="end"
              >
                <b-button
                  variant="outline-primary"
                  class="mb-2 ml-1 mt-1"
                  @click="exportXlsx"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  Exportar excel
                </b-button>
                <b-button
                  variant="outline-warning"
                  class="ml-1 mt-1 mb-2"
                  @click="$refs.negativar.show()"
                >
                  Negativar Operações
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="ml-1 mt-1 mb-2"
                  @click="$refs.negativacaoEmLote.show()"
                >
                  Operações em Lote
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row
            class="d-flex justify-content-between"
          >
            <b-col
              cols="3"
            >
              <label>Filtro por Credor</label>
              <v-select
                v-model="credorFilter"
                multiple
                :options="optionsCredor"
              />
            </b-col>
            <b-col
              cols="3"
              class="mb-1"
            >
              <label>Filtro por Status</label>
              <v-select
                v-model="statusFilter"
                multiple
                :options="optionsStatus"
              />
            </b-col>
            <b-col
              cols="3"
              class="mb-1"
            >
              <label>Filtro por Vencimento</label>
              <v-select
                v-model="vencimentoFilter"
                multiple
                :options="optionsVencimento"
              />
            </b-col>
            <b-col
              cols="3"
              class="mb-1"
            >
              <label>Filtro por Perfil</label>
              <v-select
                v-model="perfilFilter"
                multiple
                :options="optionsPerfil"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row align-h="between">
            <b-col
              lg="1"
              class="my-1"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="[10, 15, 20]"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="my-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Pesquisar"
                  debounce="800"
                />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">

          <b-overlay
            :show="load"
            variant="transparent"
          >
            <b-table
              hover
              striped
              bordered
              responsive
              selectable
              select-mode="single"
              :per-page="perPage"
              :current-page="currentPage"
              :items="listFiltered"
              :fields="fields"
              :filter="filter"
              @row-clicked="rowCliked"
              @filtered="onFiltered"
            >
              <!--Seleciona todos-->
              <template #head(check)>
                <b-form-checkbox
                  v-model="selectAll"
                />
              </template>
              <template #cell(check)="data">
                <b-form-checkbox
                  v-model="op"
                  :value="data.item"
                />
              </template>
              <template #cell(valor_nominal)="data">
                {{ data.item.valor_nominal ? valorBr(parseFloat(data.item.valor_nominal), true) : '' }}
              </template>
            </b-table>
          </b-overlay>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="negativar"
      title="Negativar Operacoes"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Negativar"
      :ok-disabled="descricaoNegativacao === ''"
      @ok="negativarOperacoes()"
    >
      <b-row>
        <b-col>
          Descrição da Negativação:
          <b-form-input
            v-model="descricaoNegativacao"
            type="text"
            class="mt-1"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="negativacaoEmLote"
      title="Escolhe a ação"
      cancel-title="Cancelar"
      cancel-variant="danger"
      :ok-disabled="!file || tipoAcao.length <=0"
      @ok="negativarEmLote()"
    >
      <b-form>
        <b-form-group
          label="Selecione o arquivo"
          label-for="name"
        >
          <b-form-file
            v-model="file"
            drop-placeholder="Arquivo"
          />
        </b-form-group>
        <label>Tipo da Ação</label>
        <v-select
          v-model="tipoAcao"
          :options="tiposAcao"
        />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  VBModal,
  BPagination,
  BInputGroup,
  BFormInput,
  BCard,
  BFormCheckbox,
  BButton,
  BAlert,
  BOverlay,
  BForm,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { saveAs } from 'file-saver'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import axios from '@/../axios-auth'
import { util } from '@/libs/util'

const ExcelJS = require('exceljs')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BCard,
    BFormCheckbox,
    BButton,
    BAlert,
    vSelect,
    BOverlay,
    BForm,
    BFormFile,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  mixins: [util],
  data() {
    return {
      options: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      selected: [],
      selectAll: false,
      filter: null,
      statusNegativacao: null,
      fields: [
        { key: 'check' },
        { key: 'operacao_id', label: 'operacao ID' },
        { key: 'cliente_id', label: 'Cliente ID' },
        { key: 'nome_credor', label: 'credor' },
        { key: 'nome_cliente', label: 'Cliente' },
        { key: 'cpf', label: 'cpf/cnpj' },
        { key: 'data_vencimento', label: 'Vencimento' },
        { key: 'numero_operacao', label: ' Número Operação' },
        { key: 'valor_nominal', label: 'Valor Nominal' },
        { key: 'data_negativacao', label: 'Data Negativação' },
        { key: 'status_negativacao', label: 'Status Negativação' },
        { key: 'perfil_cliente', label: 'Perfil' },
      ],
      items: [],
      op: [],
      descricaoNegativacao: '',
      credorFilter: [],
      statusFilter: [],
      totalOperacaoId: null,
      load: true,
      file: null,
      tiposAcao: [
        'NEGATIVAR',
        'NAO NEGATIVADO',
      ],
      tipoAcao: [],
      vencimentoFilter: [],
      totaisCpf: 0,
      listOnFiltered: [],
      perfilFilter: [],
    }
  },
  computed: {
    optionsCredor() {
      const { items } = this
      const credores = []
      items.map(item => {
        credores.push(item.nome_credor)
      })
      const uniqueCredores = [...new Set(credores)]
      return uniqueCredores
    },
    optionsStatus() {
      const { items } = this
      const status = []
      items.map(item => {
        status.push(item.status_negativacao)
      })
      const uniqueStatus = [...new Set(status)]
      return uniqueStatus
    },
    optionsVencimento() {
      const { items } = this
      const vencimentos = []
      items.map(item => {
        const match = item.data_vencimento.match(/(\d{4})/)
        if (match) {
          const ano = parseInt(match[1])
          if (!Number.isNaN(ano)) {
            if (!vencimentos.includes(ano)) {
              vencimentos.push(ano)
            }
          }
        }
      })
      return vencimentos
    },
    optionsPerfil() {
      const { items } = this
      const uniquePerfils = [...new Set(items.map(item => item.perfil_cliente))]
      return uniquePerfils
    },
    listFiltered() {
      const { items, credorFilter: cF, statusFilter: sF, vencimentoFilter: vF, perfilFilter: pF } = this
      let list = items

      // Filtro por credores
      if (cF.length > 0) {
        list = list.filter(item => cF.includes(item.nome_credor))
      }

      // Filtro por status
      if (sF.length > 0) {
        list = list.filter(item => sF.includes(item.status_negativacao))
      }

      // Filtro por ano de vencimento
      if (vF.length > 0) {
        const anosFiltrados = vF.map(Number)
        list = list.filter(item => {
          const match = item.data_vencimento.match(/(\d{4})/)
          if (match) {
            const ano = parseInt(match[1])
            if (!Number.isNaN(ano) && anosFiltrados.includes(ano)) {
              return true
            }
          }
          return false
        })
      }
      if (pF.length > 0) {
        list = list.filter(item => pF.includes(item.perfil_cliente))
      }

      this.totalOperacaoId = list.length
      this.totalRows = list.length
      this.totaisCpf = new Set(list.map(item => item.cliente_id)).size
      return list
    },

  },
  watch: {
    selectAll(n) {
      n ? this.selecionarTodos() : this.limparSelecao()
    },
  },
  async created() {
    await axios.get('api/v1/operacoes/listarNegativacaoPendente', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.load = false
        // eslint-disable-next-line array-callback-return
        res.data.dados.map(dt => {
          this.items.push({
            credor_id: dt.credor_id,
            nome_cliente: dt.nome_cliente,
            cpf: this.cpfCNPJ(dt.cpf),
            nome_credor: dt.nome_credor,
            operacao_id: dt.operacao_id,
            cliente_id: dt.cliente_id,
            data_vencimento: dt.data_vencimento ? this.formatTimezone(dt.data_vencimento) : '',
            numero_operacao: dt.numero_operacao,
            data_negativacao: dt.data_negativacao ? this.formatTimezone(dt.data_negativacao) : '',
            valor_nominal: dt.valor_nominal,
            status_negativacao: dt.status_negativacao,
            endereco: dt.endereco,
            bairro: dt.bairro,
            cep: dt.cep,
            cidade: dt.cidade,
            uf: dt.uf,
            telefone: dt.telefone,
            email: dt.email,
            perfil_cliente: dt.perfil_cliente,
          })
        })
      })
    this.totalRows = this.items.length
  },
  mounted() {
    this.$bvModal.show('modal-filtro')
  },
  methods: {
    async rowCliked(item) {
      this.$router.push({ name: 'financeiro.listnegativacao', params: { id: item.cliente_id } })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalOperacaoId = filteredItems.length
      this.totalRows = filteredItems.length
      this.totaisCpf = new Set(filteredItems.map(item => item.cliente_id)).size
      this.currentPage = 1
      this.listOnFiltered = filteredItems
    },
    clearModal() {
      this.descricaoNegativacao = ''
      this.op = []
      this.selectAll = null
      this.filter = null
      this.credorFilter = []
      this.statusFilter = []
    },
    negativarOperacoes() {
      const verificarOperacaoNegativada = this.op.map(status => status.status_negativacao)
      const body = {
        classificacao_negativacao_id: 1,
        ops_ids: this.op.map(id => id.operacao_id),
        description: this.descricaoNegativacao,
        motivoId: 50,
      }
      if (verificarOperacaoNegativada.includes('Negativado')) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione apenas as operações NÃO NEGATIVADAS!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.clearModal()
      } else {
        this.$swal({
          title: '',
          text: 'Deseja negativar essas operações ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, confirmo!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.load = true
            axios.put('api/v1/operacoes/update/lote/negativado/operacao/', body, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                accept: 'application/json',
              },
            }).then(() => {
              this.load = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificação',
                  icon: 'BellIcon',
                  text: 'Negativação feita com sucesso',
                  variant: 'success',
                },
              }, {
                position: 'top-center',
                timeout: 1500,
              })
              this.$nextTick(() => {
                this.clearModal()
                this.updateGrid()
              })
            }).catch(error => {
              this.load = false
              this.$swal({
                title: 'Error! Algo inesperado aconteceu',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.clearModal()
              this.updateGrid()
            })
          }
        })
      }
    },
    async exportXlsx() {
      const file = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },
    async getXlsx() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Negativação Clientes')
      const itemsPlan = this.listOnFiltered.length ? this.listOnFiltered : this.listFiltered

      worksheet.columns = [
        { key: 'operacao_id', header: 'OPERACAO ID' },
        { key: 'cliente_id', header: 'CLIENTE ID' },
        { key: 'nome_credor', header: 'CREDOR' },
        { key: 'nome_cliente', header: 'CLIENTE' },
        { key: 'cpf', header: 'CPF/CNPJ' },
        { key: 'data_vencimento', header: 'VENCIMENTO' },
        { key: 'numero_operacao', header: ' NÚMERO OPERAÇÃO' },
        { key: 'valor_nominal', header: 'VALOR NOMINAL' },
        { key: 'data_negativacao', header: 'DATA NEGATIVAÇÃO' },
        { key: 'status_negativacao', header: 'STATUS NEGATIVAÇÃO' },
        { key: 'endereco', header: 'ENDEREÇO' },
        { key: 'bairro', header: 'BAIRRO' },
        { key: 'cep', header: 'CEP' },
        { key: 'cidade', header: 'CIDADE' },
        { key: 'uf', header: 'UF' },
        { key: 'telefone', header: 'TELEFONE' },
        { key: 'email', header: 'E-MAIL' },
        { key: 'perfil_cliente', header: 'PERFIL CLIENTE' },
      ]
      const wk1Columns = [
        'valor_nominal',
      ]
      const totais = {
        wk1: {},
      }
      itemsPlan.map(item => {
        worksheet.addRow([
          item.operacao_id,
          item.cliente_id,
          item.nome_credor,
          item.nome_cliente,
          item.cpf,
          item.data_vencimento,
          item.numero_operacao,
          parseFloat(item.valor_nominal),
          item.data_negativacao,
          item.status_negativacao,
          item.endereco,
          item.bairro,
          item.cep,
          item.cidade,
          item.uf,
          item.telefone,
          item.email,
          item.perfil_cliente,
        ])
        wk1Columns.map(w => {
          totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        valor_nominal: totais.wk1.valor_nominal,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Negativações Clientes', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    updateGrid() {
      this.load = true
      this.items = []
      this.op = []
      axios.get('api/v1/operacoes/listarNegativacaoPendente', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.load = false
          // eslint-disable-next-line array-callback-return
          res.data.dados.map(dt => {
            this.items.push({
              credor_id: dt.credor_id,
              nome_cliente: dt.nome_cliente ? this.fisrtLastName(dt.nome_cliente) : '',
              cpf: this.cpfCNPJ(dt.cpf),
              nome_credor: dt.nome_credor,
              operacao_id: dt.operacao_id,
              cliente_id: dt.cliente_id,
              data_vencimento: dt.data_vencimento ? this.formatTimezone(dt.data_vencimento) : '',
              numero_operacao: dt.numero_operacao,
              data_negativacao: dt.data_negativacao ? this.formatTimezone(dt.data_negativacao) : '',
              status_negativacao: dt.status_negativacao,
              endereco: dt.endereco,
              bairro: dt.bairro,
              cep: dt.cep,
              cidade: dt.cidade,
              uf: dt.uf,
              telefone: dt.telefone,
              email: dt.email,
              perfil_cliente: dt.perfil_cliente,
            })
          })
        })
    },
    selecionarTodos() {
      this.op = []
      if (this.filter) {
        this.listFiltered.map(item => {
          if (item.nome_credor.toLowerCase().includes(this.filter.toLowerCase())
              || item.nome_cliente.toLowerCase().includes(this.filter.toLowerCase())
              || item.cpf.trim().includes(this.filter.trim())) { this.op.push(item) }
        })
      } else {
        this.listFiltered.map(item => {
          this.op.push(item)
        })
      }
    },
    limparSelecao() {
      this.op = []
    },
    negativarEmLote() {
      this.load = true
      const formData = new FormData()
      formData.append('planilha', this.file)
      formData.append('tipo_acao', this.tipoAcao)
      axios.post('api/v1/operacoes/negativacao/lote', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.load = false
          this.file = null
          this.tipoAcao = []
          this.clearModal()
          this.updateGrid()
          this.$swal({
            title: 'Importação realizada com sucesso',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.load = false
          this.file = null
          this.tipoAcao = []
          this.clearModal()
          this.updateGrid()
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>
